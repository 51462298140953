<template>
   <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/liveManage"
          >直播管理 /</router-link>
          <span class="crumbs_item crumbs_last"> 预约人员</span>
        </span>
      </div>
    </div>

    <div class="selects">
      <div class="findItem">
        <a-input
          v-model="name"
          placeholder="请输入姓名"
        />
        <a-input
          v-model="mobile"
          placeholder="请输入手机号"
        />
        <a-form-item label="预约成功时间：" style="display: inline-flex; margin: 0">
          <a-range-picker
            valueFormat="YYYY-MM-DD"
            v-model="findtime"
            @change="onLiveTime"
          ></a-range-picker>
        </a-form-item>
      </div>
      <div class="findItem">
        <a-button type="primary" @click="search()" v-hasPermi="['live:advancelist:query']">搜索</a-button>
        <a-button class="all_btn_border btnBorder" @click="reset">重置</a-button>
      </div>
    </div>
    
    <a-table
      class="table-template"
      :rowKey="(item) => item.id"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }" @change="onPage"
    >
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (current - 1) * size + index + 1 }}
      </template>
    </a-table>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    align: 'center',
    dataIndex: "index",
    scopedSlots: {
      customRender: "index"
    }
  },
  {
    title: '用户名',
    align: 'center',
    dataIndex: 'name',
  },
  {
    title: '手机号码',
    align: 'center',
    dataIndex: 'mobile',
  },
  {
    title: '预约成功时间',
    align: 'center',
    dataIndex: 'modifyTime',
  }
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      tableData: [],
      loading:false,
      name: '', // 姓名
      applyStartDate: '', // 预约成功开始时间
      applyEndDate: '', // 预约成功结束时间
      mobile: '', // 手机号
      id: '',
      findtime: ['', ''],
      total: 0, 
      current: 1, //页码
      size: 10, // 页数 
    }
  },
  // 事件处理器
  methods: {
    onPage(e){
     this.current = e.current
     this.size = e.pageSize
      this.getList();
    },

    // 重置
    reset() {
      this.name = ''; // 姓名
      this.mobile = ''; // 手机号
      this.applyStartDate = ''; // 预约成功开始时间
      this.applyEndDate = ''; // 预约成功结束时间
      this.findtime = ['', ''];
      this.current = 1
      this.getList()
    },

    search(){
     this.current = 1
      this.getList(this.id);
    },

    // 预约成功时间确认
    onLiveTime(date) {
      this.applyStartDate = date[0];
      this.applyEndDate = date[1];
    },
    getList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/liveApply/getApplyList",
        params: {
          name: this.name,
          applyStartDate: this.applyStartDate,
          applyEndDate: this.applyEndDate,
          mobile: this.mobile,
          id: this.id,
          pageNum: this.current,
          pageSize: this.size,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.id = this.$route.query.id;
    this.getList(this.id);
   },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>

.all_main_wrap{
  min-height: 100%;
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .btn {
    margin-right: 24px;
  }

  /deep/.ant-input {
    width: 240px;
    margin-right: 24px;
  }
}
.selects {
  // width: 929px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-end;
  justify-content: space-between;
}
.findItem {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  // /deep/.ant-form-item-control-wrapper{
  //   width: 240px !important;
  //   height: 29px !important;
  // }
  /deep/.ant-input {
    width: 300px !important;
    height: 29px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
  .ant-btn {
    margin-right: 24px;
  }
  .ant-btn:last-child {
    margin-right: 0;
  }
}
</style>
